.user-room-page{
    height: 100vh;
    width: 100%;
    background-color: #000222;
    background-image: 
        radial-gradient(at 47% 33%, hsl(265.71, 62%, 15%) 0, transparent 59%), 
        radial-gradient(at 82% 65%, hsl(237.62, 100%, 19%) 0, transparent 55%);
    display: flex;
    flex-direction: column;
}
// .user-room-music-player{
//     height: 10vh;
//     width: 100%;
//     margin-top: 10vh;
//     background-color: paleturquoise;
// }
.user-room-your-chamber{
    height: 8vh;
    width: 91%;
    margin-left: 9%;
    // margin-top: 12vh;
    // background-color: bisque;
    color: var(--textcol);
    display: flex;
    align-items: center;
}
.hamburger-menu-icon-divs{
    height: 10vh;
    margin-left: 3%;
    margin-right: 1%;
    display: flex;
    align-items: center;
}
.hamburger-menu-icon-img{
    height: 6vh;
    width: 3vw;
}
.user-room-chamber-title{
    font-size: 2rem;
    font-weight: bold;
}
.user-room-bottom-container{
    height: 80vh;
    width: 95%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
}
.user-room-left{
    height: 80vh;
    width: 30%;
    margin-left: 2.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--textcol);
    // background-color: palegreen;
}
.user-room-your-task-card{
    height: 70vh;
    width: 100%;
    border-radius: 20px;
    margin-top: 2.5vh;
    // background-color: gray;
}
.user-room-centre{
    height: 80vh;
    width: 60%;
    margin-left: 2.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--textcol);
    // background-color: azure;
}
.user-room-chat-card{
    height: 70vh;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: 2.5vh;
    display: flex;
    flex-direction: column;
    background-color: gray;
}
.user-room-chat-card-top{
    height: 10vh;
    width: 100%;
    background-color: #014122;
    display: flex;
    align-items: center;
    justify-content: right;
}
.user-room-chat-card-options{
    height: 3vw;
    width: 3vw;
    border-radius: 1.5vw;
    margin-right: 8px;
    // background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.user-room-chat-card-options-img{
    height: 3vw;
    width: 2vw;
    object-fit: contain;
}
.user-room-chat-card-centre{
    height: 50vh;
    width: 100%;
    background-color: gray;
}
.user-room-chat-card-bottom{
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #014122;
}
.user-room-chat-card-bottom-option-msg{
    margin-right: 2vw;
}
.user-room-msg-input{
    width: 30vw;
    height: 4vh;
}
.user-room-chat-card-bottom-options{
    height: 3vw;
    width: 3vw;
    border-radius: 1.5vw;
    margin-right: 8px;
    // background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.user-room-chat-card-bottom-options-img{
    height: 3vw;
    width: 2vw;
    object-fit: contain;
}
.user-room-chat-card-bottom-button-options{
    height: 35px;
    width: 100px;
    border-radius: 50px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0d66d0;
    cursor: pointer;
}
.user-room-right{
    height: 80vh;
    width: 10%;
    margin-left: 2.5%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    color: var(--textcol);
    // background-color: palegreen;
}
.user-room-member-card{
    height: 55vh;
    width: 100%;
    border-radius: 20px;
    margin-top: 6vh;
    text-align: center;
    font-size: 0.8rem;
    box-shadow: 5px 10px 50px #000;
    margin-bottom: 10px;
    backdrop-filter: blur(25px) saturate(200%);
    -webkit-backdrop-filter: blur(25px) saturate(200%);
    background-color: rgba(17, 25, 40, 0.47);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}
.user-room-right-text{
    width: 50%;
    line-height: 8.5vh;
    font-size: 3rem;
    font-weight: bold;
}