.rooms-components-home-page{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.rooms-component-left{
    height: 100vh;
    width: 50%;
    // background-color: aqua;
}
.rooms-component-lottie-animation{
    width: 100%;
    height: 70vh;
    margin-top: 20vh;
    // background-color: aquamarine;
}
.rooms-component-right{
    height: 100vh;
    width: 50%;
    margin-right: 2%;
    padding-right: 5vw;
    display: flex;
    flex-direction: column;
    // position: absolute;
    // top: 10vh;
    align-items: left;
    text-align: left;
    justify-content: center;
    color: var(--textcol);
    // background-color: aquamarine;
}
.rooms-component-right-text{
    font-size: 3.3rem;
}
.rooms-component-right p{
    margin-top: 2vh;
    line-height: 3vh;
}
.rooms-component-right ul{
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 3vh;
    margin-left: 2vw;
    line-height: 4vh;
}