.blog-top {
    background: rgba(0,0,0,.3);
    height: 55vh;
    width: 100%;
    position: relative;
    z-index: 1;
}

.blog-top:before {
    content:'';
    background: url('https://images.unsplash.com/photo-1432821596592-e2c18b78144f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: -1; */
}
.blog-top-navbar{
    // height: 20vh;
    // width: 80%;
    position: absolute;
    top: 13vh;
    left: 9vw;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.blog-top-read-blogs-1{
    height: 40vh;
    width: 15vw;
    background-color: gray;
    filter: blur(4px);
    box-shadow: 5px 2px 5px 2px black;
    transition: all 1s;
}
.blog-top-read-blog-1-img{
    height: 40vh;
    width: 15vw;
    object-fit: cover;
}
    .blog-top-read-button:hover{
        transform: scale(1.1);
    }
.blog-top-read-blogs-2{
    height: 30vh;
    width: 12vw;
    margin-left: -7vw;
    filter: blur(4px);
    box-shadow: 5px 2px 5px 2px black;
}

.blog-top-read-blog-2-img{
    height: 30vh;
    width: 12vw;
    object-fit: cover;
}
.blog-top-read-blogs-3{
    height: 20vh;
    width: 10vw;
    margin-left: -7vw;
    background-color: gray;
    filter: blur(4px);
    box-shadow: 5px 2px 5px 2px black;
}

.blog-top-read-blog-3-img{
    height: 20vh;
    width: 10vw;
    object-fit: cover;
}
.blog-top-read-button{
    height: 5vh;
    width: 8vw;
    border-radius: 20px;
    background-color: orange;
    margin-left: -16vw;
    z-index: 5;
    transition: all 1s;
    cursor: pointer;
}