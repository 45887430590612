@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
.signin-page-container{
    background-image: url(https://images.unsplash.com/photo-1623479322729-28b25c16b011?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}
.signin-page{
    // margin-top: 10vh;
    
    background-color: transparent;
    
    display: flex;
    flex-direction: row;
}
.signin-page-left{
    height: 80vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    color: aliceblue;
    font-family: Montserratt;
    font-size: 4rem;
}
.signin-left-text1{
    margin-left: 10vw;
    font-size: 80px;
    text-shadow: 1px 1px grey;
}
.signin-page-right{
    height: 80vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}