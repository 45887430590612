.signup-page-container{
    background-image: url(https://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}
.signup-page{
    // margin-top: 10vh;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.signup-page-left{
    height: 85vh;
    width: 50%;
    display: flex;
    align-items: center;
}
.signup-page-right{
    height: 85vh;
    width: 50%;
    display: flex;
    align-items: center;
}