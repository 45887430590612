@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Cormorant:ital,wght@1,300;1,400;1,500&family=Montserrat:wght@300;400;500&display=swap');
*{
    margin:0px;
    padding: 0;
    
}

.navbar-container{
    width: 100%;
    height:15vh;
    display: flex;
    align-items: center;
    // position: fixed;
	// top: 0px;
    overflow: hidden;
    z-index: 999;    
    background: transparent;
    // background-color: #000222;
    // background-image: 
    //     radial-gradient(at 55% 45%, #2a0b53 0, transparent 59%), 
        // radial-gradient(at 82% 65%, hsl(237.62, 100%, 19%) 0, transparent 55%);
}
.navbar-container h1{
    color: var(--textcol);
    margin-left: 8%;
    padding-left: 20px;
    font-family: Archivo Black;
    font-size: 2.3rem;
    color: green;
}
.navbar-logo{
    color: white;
}
nav{
    flex: 1;
    text-align: right;
    margin-right: 5%;
}
nav ul li{
    list-style: none;
    display: inline-block;
    margin-right: 30px;
    color: var(--textcol);
    font-size: 1.1rem;
    
}
nav ul li button{
    padding: 5px 10px;
    border-radius: 20px;
    background-color: orange;
    outline: none;
    outline-offset: none;
    border: none;
    color: var(--textcol);
}
li:hover{
    cursor: pointer;
}
a{
    text-decoration: none;
    color: var(--textcol);
}
.hamburger-menu-icon{
    display: none;
}
.menu-list{
    transition: all 1s linear;
}


@media (max-width:1023px) {
    .hamburger-menu-icon{
        display: block;
        margin-right: 2vw;
        
        cursor:pointer;
    }
    nav ul{
        width: 50%;
        background-color:#000222;
        position: absolute;
        top:10vh;
        right: 0px;
        z-index: 6;
    }
    nav ul li{
        display: block;
        margin: 10px 20px 10px 0px;
    }
    .navbar-logo{
        
    //   margin-left: 25vw;
    }
    .menu-list-closed{
        display: none;
    }
    
}