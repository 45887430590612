
.signin-form-component
{
    height: 70vh ;
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #0F2027;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    float: right;
    border: none;
    border-radius: 50px;
    margin: 40px 50px 0px 0px;
    color: var(--textcol);

}
label{
    display:block;
    margin-top: 20px;
}
input{
    padding: 5px;
    margin-top: 2px;
    outline: none;
    border: none;
    border-radius: 20px;
}
.sigin-form-title{
    padding-bottom: 20px;
    color: var(--textcol)
}
.signin-form-subtitle{
    color: var(--textcol);
}
.signin-submit-button{
    width: 100px;
    padding: 10px;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
}
.user-home-button-text{
    color: #0A0A0A;
}
.signin-forget-password{
    display: block;
    text-decoration: none;
    cursor: pointer;
    margin-top: 20px;
}
.signin-email-input{
    width: 25vw;
}

.signin-password-input{
    width: 25vw;
}