.signup-form-component
{
    height: 75vh ;
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #0F2027;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to right, #010a17, #151a70, #2c24ad);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to right, #010a17, #151a70, #2c24ad); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: transparent;
    box-shadow: 5px 3px 5px 3px #2C2828;
    backdrop-filter: blur(8px);
    float: right;
    border: none;
    border-radius: 50px;
    margin: 40px 50px 0px 0px;
    color: var(--textcol);

}
label{
    display:block;
    margin-top: 20px;
}
input{
    padding: 5px;
    margin-top: 2px;
    outline: none;
    border: none;
    border-radius: 20px;
}
.sigup-form-title{
    padding-bottom: 20px;
    color: var(--textcol)
}
.signup-form-subtitle{
    color: var(--textcol);
}
.signup-register-button{
    width: 120px;
    padding: 10px;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.signup-name-input{
    width: 25vw;
}
.signup-username-input{
    width: 25vw;
}
.signup-email-input{
    width: 25vw;
}
.signup-password-input{
    width: 25vw;
}
.signup-confirm-password-input{
    width: 25vw;
}