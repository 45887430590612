.collab-home-page{
    display: flex;
    height: 90vh;
    width: 100%;
    flex-direction: row;
    // background-color: #000222;
    // background-image: 
    //     radial-gradient(at 47% 33%, hsl(265.71, 62%, 15%) 0, transparent 59%), 
    //     radial-gradient(at 82% 65%, hsl(237.62, 100%, 19%) 0, transparent 55%);
}
.collab-home-left{
    height: 100vh;
    width: 50%;
    // background-color: bisque;
}
.collab-home-right{
    height: 100vh;
    width: 50%;
    z-index: 10;
    margin-right: 2%;
    padding-right: 5vw;
    display: flex;
    flex-direction: column;
    // position: absolute;
    // top: 10vh;
    align-items: right;
    text-align: left;
    justify-content: center;
    color: var(--textcol);
    // background-color: aqua;
}
.collab-home-right p{
    font-size: 1.2rem;
}
.collab-home-right ul{
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 3vh;
    margin-left: 2vw;
    line-height: 4vh;    
}
.collab-text-title{
    padding-bottom: 20px;
    font-size: 3.3rem;
    font-family: Montserrat;
    line-height: 10vh;
    // text-shadow: 3px 3px 2px #474747;
}
.collab-page-lottie-animation{
    width: 100%;
    height: 60vh;
    margin-top: 20vh;
    // background-color: aquamarine;
}