.code-editor-page-container{
    height: 140vh;
    background-color: #000222;
    background-image: 
        radial-gradient(at 47% 33%, hsl(265.71, 62%, 15%) 0, transparent 59%), 
        radial-gradient(at 82% 65%, hsl(237.62, 100%, 19%) 0, transparent 55%);
}
.code-editor-page{
    height: 120vh;
    width: 100%;
    margin: 5%, 5%;
    
    display: flex;
    flex-direction: column;
}
.code-editor-container{
  margin-top: 10px;
}
.code-editor-first-part{
    // height: 5vh;
    // margin-top: 10vh;
    width: 100%;
    // background-color: aqua;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.code-editor-first-part-left{
    display: flex;
    flex-direction: row;
    margin-left: 6%;
    align-items: center;
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom:8px ;
    color: var(--textcol);
}


.close-editor-right-button-img{
    height: 3vh;
    width: 5vw;
    object-fit: contain;
}
.code-editor-second-part{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.code-editor-left{
    height: auto;
    width: 70%;
    margin-left: 5%;
    margin-top: 0px;
    // background-color: aquamarine;
    display: flex;
    flex-direction: column;
    margin-bottom: 5vh;
    background-color: black;
    color: green;
}
.code-editor-left-content{
    height: 92vh;
    width: 96%;
    background-color: black;
}
.code-editor-right{
    height: auto;
    margin-right: 0px;
    margin-left: 2%;
    width: 20%;
    // background-color: bisque;
    display: flex;
    flex-direction: column;
    margin-bottom: 5vh;
}
.code-editor-user-1{
    height: 25vh;
    width: 18vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #000222;
    background-image: 
        radial-gradient(at 47% 33%, hsl(263.53, 100%, 50%) 0, transparent 59%), 
        radial-gradient(at 82% 65%, hsl(237.84, 100%, 49%) 0, transparent 55%);
    margin-bottom: 8px;
    // background-color: salmon;
}
.code-editor-user1-img img{
    height: 8vw;
    width: 8vw;
    border-radius: 4vw;
    object-fit: cover;
    
}
.code-editor-user-2{
    height: 25vh;
    width: 18vw;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #000222;
    background-image: 
        radial-gradient(at 47% 33%, hsl(263.53, 100%, 50%) 0, transparent 59%), 
        radial-gradient(at 82% 65%, hsl(237.84, 100%, 49%) 0, transparent 55%);
}
.code-editor-user2-img img{
    height: 8vw;
    width: 8vw;
    border-radius: 4vw;
    object-fit: cover;
   
}
.code-editor-team-message{
    height: 35vh;
    width: 18vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--textcol);
    // background-color: salmon;
}
.code-editor-message-title{
    font-size: 1.6rem;
    margin-top: 0px;
}
.code-editor-message-card{
    height: 28vh;
    width: 18vw;
    background-color: whitesmoke;
    margin-top: 10px;
}
.code-editor-message-card img{
    height: 28vh;
    width: 18vw;
    object-fit: cover;
}
.code-editor-right-options{
    // background-color: seagreen;
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.code-editor-video-call{
    height: 5vh;
    width: 10vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: orange;
    margin-right: 2vw;
    border-radius: 20px;
    transition: all 1s;
    cursor: pointer;
    outline: none;
    border: none;
}
.code-editor-audio-call{
    height: 5vh;
    width: 10vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: orange;
    margin-right: 2vw;
    border-radius: 20px;
    transition: all 1s;
    cursor: pointer;
    outline: none;
    border: none;
}