@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
html{
  margin: 0px;
  padding:0px;
}
:root{
  --textcol:#F8F8FF;
  --backgroundcol:#000222;
  --darktext:black;
  --backgroundlight:white;
}
body{
  font-family: Montserrat;
}