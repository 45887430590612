@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Cormorant:ital,wght@1,300;1,400;1,500&family=Montserrat:wght@300;400;500&display=swap');

    .dashboard-home-page-container{
    // padding: 2px 2vw;
    height: auto;
    background-color: #000222;
    background-image: 
        radial-gradient(at 47% 33%, hsl(265.71, 62%, 15%) 0, transparent 59%), 
        radial-gradient(at 82% 65%, hsl(237.62, 100%, 19%) 0, transparent 55%);
}
.dashboard-page-container{
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: row;
    // background-color: #000222;
    // background-image: 
    //     radial-gradient(at 47% 33%, hsl(265.71, 62%, 15%) 0, transparent 59%), 
    //     radial-gradient(at 82% 65%, hsl(237.62, 100%, 19%) 0, transparent 55%);
}
.home-page-text1{
    z-index: 10;
    height: 90vh;
    // margin-top: 10vh;
    width: 45%;
    margin-left: 2%;
    padding-left: 5vw;
    display: flex;
    flex-direction: column;
    // position: absolute;
    // top: 10vh;
    align-items: left;
    text-align: left;
    justify-content: center;
    color: var(--textcol);
    // background-color: aqua;
    
}
.home-start-button{
    width: 150px;
    padding: 10px;
    cursor: pointer;
    border-radius: 20px;
    margin-top: 20px;
}
.home-text-title{
    padding-bottom: 20px;
    font-size: 3.3rem;
    font-family: Montserrat;
    line-height: 10vh;
    // text-shadow: 3px 3px 2px #474747;
}

.home-page-lottie-animation{
    width: 50%;
    height: 50vh;
    margin-top: 25vh;
    // background-color: aquamarine;
}
