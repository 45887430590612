.shareblogpagecontainer{
    background-color: #000222;
    background-image: 
        radial-gradient(at 47% 33%, hsl(265.71, 62%, 15%) 0, transparent 59%), 
        radial-gradient(at 82% 65%, hsl(237.62, 100%, 19%) 0, transparent 55%);
}
.ShareBlogpage {
    width: 100%;
    height: 85vh;
    /*padding: 6rem 1rem;*/
    display: flex;
}
.share-blog-navbar{
    background-color: aqua;
}
.blog-page-left{
    height: 80vh;
    width: 60%;
    // margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-color: aqua;
}
.blog-page-right{
    height: 80vh;
    width: 50%;
    // margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: aquamarine;
}
.blog-page-top{
    font-size: 2rem;
    color: var(--textcol);
}
.blog-page-left form{
    width: 90%;
    // background-color: aquamarine;

}
.blog-page-top form textarea{
    width: 40vw;

}
.blog-text-input{
    height: 60vh;
    width: 100%;
    margin-top: 2vh;
    margin-left: 10%;
    display: grid;
}
.blog-share{
    height: 53vh;
    width: 98.9%;
    display: flex;
    resize: none;
    border: 8px solid gray;
    outline: none;
}
.blog-share:hover{
    outline: none;
}
.blog-share-bottom{
    height: 3vh;
    width: 98.9%;
    background-color: gray;
    border: 8px solid gray;
    display: flex;
    align-items: center;
    justify-content: right;
}
.blog-bottom-submit{
    margin-right: 2vw;
}
.blog-share-more-options{
    display: flex;
    flex-direction: row;
}
.blog-share-more-options:hover{
    .blog-share-more-before{
        background-color: #fff;
    }
    .blog-share-more-after{
        height: 3vw;
        width: 6vw;
        background-color: #fff;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }
}
.blog-share-more-before{
    height: 3vw;
    width: 3vw;
    margin-left: 2vw;
    background-color: #fff;
    border-radius: 1.5vw;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.blog-share-more-img{
    height: 2vw;
    width: 2vw;
}
.blog-share-more-after{
    display: flex;
    flex-direction: row;
    margin-left: -3vw;
}
.blog-share-more-after1{
    height: 3vw;
    width: 6vw;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.blog-share-more-after2{
    width: 0; 
    height: 0; 
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent; 
    
    border-right:25px solid gray;
    margin-left: -1.6vw;
}
.blog-submit-button{
    height: 4vh;
    width: 8vw;
    margin-bottom: 5px;
    border-radius: 20px;
    background-color: #0D66D0;
    color: #fff;
    border: none;
    transition: all 1s;
    cursor: pointer;
}
.blog-submit-button:hover{
    transform:scale(1.1);
}
.blog-page-right-top{
    height: 80%;
    width: 100%;
    // background-color: beige;
}
.blog-page-right-centre{
    height: 10%;
    width: 100%;
    margin-top: -5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: var(--textcol);
    // background-color: palegoldenrod;
}
.blog-page-right-bottom{
    width: 100%;
    height: 10%;
    margin-top: 2vh;
    display: flex;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: var(--textcol);
    // background-color: palegreen;
}
.blog-page-right-bottom-button{
    height: 5vh;
    width: 15vw;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: orange;
}