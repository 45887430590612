*{
    margin:0px;
    padding: 0;
    
}

.footer-container1{
    width: 100%;
    height:10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;    
    background: var(--backgroundcol);
}
.footer-container-left{
    height: 10vh;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    // background-color: aqua;
}
.footer-container-right{
    height: 10vh;
    width: 80%;
    display: flex;
    justify-content: right;
    align-items: center;
    text-align: right;
    // background-color: aquamarine;
}
.footer-container-left-title{
    color: var(--textcol);
    font-family: Archivo Black;
    font-size: 1rem;
}
.footer-container-right-subtitle{
    color: var(--textcol);
    font-family: Archivo Black;
    font-size: 1rem;
    margin-right: 10vw;
}