*{
    margin: 0;
    padding: 0;
}
.container{
    // background: linear-gradient(to right bottom, #e1b382, #c89666);
    background: transparent;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.app-wrapper{
    min-width: 430px;
    height: 6s0vh;
    padding: 30px;
    box-sizing: border-box;
    // box-shadow:  25px -25px 49px #094d30,
    //          -25px 25px 49px #0d6940;
    box-shadow: 5px 10px 50px #000;
    margin-bottom: 10px;
    backdrop-filter: blur(25px) saturate(200%);
    -webkit-backdrop-filter: blur(25px) saturate(200%);
    background-color: rgba(17, 25, 40, 0.47);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
}
.header h1{
    color: #fff;
    text-align: center;
    margin: 30px 0;
}
.task-input{
    outline: none;
    width: 220px;
    padding: 15px;
    margin-right: 25px;
    font-size: 20px;
    color: #ccc;
    background-color: #000000;
    border: 1px solid #c89666;
    border-radius: 10px;
}
.button-add{
    width: 70px;
    padding: 15px 15px;
    font-size: 20px;
    border-radius: 10px;
    border: 0;
    background-color: #f1af71;
    cursor: pointer;
}
.todo-list-item-all{
    overflow-y: scroll;
    margin-top: 2vh;
    height: 30vh;
    width: 25vw;
}
.list-item{
    display: flex;
    margin: 20px 0;
    border: 1px solid #ccc;
    padding: 10px;
    width: 20vw;
    max-height: 30px;
}

.button-complete,
.button-delete,
.button-edit{
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 28px;
    border-radius: 50%;
}

.button-complete{
    color: #ff6c6c;
    margin-right: 10px;
}

.button-edit{
    color: #e2d029;
    margin-right: 10px;
}
.button-delete{
    color: lightseagreen;
}

.complete{
    text-decoration-style: solid;
    text-decoration-line: line-through;
    text-decoration-color: #ff6c6c;
    opacity: 0.6;
}

.list{
    width: 150px;
    background-color: #12343b;
    border: none;
    color: #ccc;
    font-size: 20px;
    padding-left: 10px;
    margin-right: 15px;
}

input:focus,
button:focus{
    outline: none;
}